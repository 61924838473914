import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


document.addEventListener('DOMContentLoaded', function() {
  let leftColumn = document.querySelector('.logo-slider--right');
  let centerColumn = document.querySelector('.logo-slider--left');

  gsap.registerPlugin(ScrollTrigger);

  gsap.to(leftColumn, {
    scrollTrigger: {
      trigger: '.partners',
      start: 'top bottom',
      end: 'bottom top',
      scrub: 2,
    },
    x: '10vw',
  });

  gsap.to(centerColumn, {
    scrollTrigger: {
      trigger: '.partners',
      start: 'top bottom',
      end: 'bottom top',
      scrub: 2,
    },
    x: '-10vw',
  });
});
